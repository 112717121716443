<template>
    <div id="business-users">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="business-users-data">
            <!-- 筛选 -->
            <business-search @onSearch="onSearch" />

            <!-- 操作按钮 -->
            <business-btns :check-keys="checkKeys" @onShowModal="showModal = true" @onSuccess="onSuccess" />

            <!-- 列表 -->
            <div class="users-list-wrapper">
                <business-users-list :list="usersList" :check-keys.sync='checkKeys' :loading="loading" @onEdit="showModal = true" @onChangePwd="showChangePwd = true" @onSuccess="onSuccess" />
            </div>

            <!-- 分页 -->
            <pagination :page.sync="page" :page-size="pageSize" :total-size="totalSize" @onPageChange="onPageChange" @onSizeChange="onSizeChange" />
        </div>

        <!-- 新建/编辑 -->
        <business-create-edit :show.sync="showModal" @onSuccess="onSuccess" />

        <!-- 修改密码 -->
        <change-pwd :show.sync="showChangePwd" />
    </div>
</template>

<script>
import BusinessSearch from './childComps/BusinessSearch'
import BusinessBtns from './childComps/BusinessBtns'
import BusinessUsersList from './childComps/BusinessUsersList'
import BusinessCreateEdit from './childComps/BusCreateEdit'
import ChangePwd from './childComps/ChangePwd'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Pagination from 'components/pagination/Pagination'

import { getUsersList } from 'network/usersManage'


export default {
    name: "BusinessUsers", // 商家用户管理
    components: {
        BusinessSearch,
        BusinessBtns,
        BusinessUsersList,
        BusinessCreateEdit,
        ChangePwd,
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/businessUsers",
                    breadcrumbName: "商家用户管理",
                },
            ],

            checkKeys: [], // 选择的用户

            nickname: '', // 商家名称
            account: '', // 商家账号
            status: 0, // 状态

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            showModal: false, // 是否显示创建/编辑弹窗
            showChangePwd: false, // 是否显示修改密码弹窗

            usersList: []
        }
    },
    created () {
        this._getUsersList(this.page, this.pageSize, {
            userType: '1'
        })
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 查询
        onSearch (values) {
            this.nickname = values.nickname || '';
            this.account = values.account || '';
            this.status = values.status || '';

            let params = {
                operatorName: values.nickname,
                username: values.account,
                userType: '1'
            }

            if (this.status > 0) {
                params.closed = this.status - 1
            }

            this.page = 1;

            this._getUsersList(this.page, this.pageSize, params)
        },

        // 切换页码
        onPageChange(page) {
            this.page = page;

            let params = {
                operatorName: this.nickname,
                username: this.account,
                userType: '1'
            }

            if (this.status > 0) {
                params.closed = this.status - 1
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        // 切换一页多少条
        onSizeChange (pageSize) {
            this.pageSize = pageSize;

            let params = {
                operatorName: this.nickname,
                username: this.account,
                userType: '1'
            }

            if (this.status > 0) {
                params.closed = this.status - 1
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        // 新建/编辑成功
        onSuccess() {
            let params = {
                operatorName: this.nickname,
                username: this.account,
                userType: '1'
            }

            if (this.status > 0) {
                params.closed = this.status - 1
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        // ---------------------网络请求-------------------
        // 获取用户列表
        _getUsersList (pageNumber, pageSize, params = {
            phone: '',
            userType: '1',
            closed: '',
            realname: '',
            username: '',
            operatorName: ''
        }) {
            this.loading = true;

            getUsersList(pageNumber, pageSize, params).then(res => {
                this.totalSize = res.totalSize;
                this.page = res.currentSize > 0 ? pageNumber : this.page;

                let list = res.data;

                let newList = [];

                class User {
                    constructor(obj) {
                        for (const key in obj) {
                            this[ key ] = obj[ key ];
                        }
                    }
                }

                for (let i = 0; i < list.length; i++) {
                    let item = list[ i ];
                    let user = new User({
                        key: item.id,
                        nickname: item.operatorName,
                        status: item.closed === 0 ? '启用' : '停用',
                        authData: item.certificationPath ? '查看' : '未填写',
                        total: '暂无数据',
                        mode: item.settlementMode == 0 ? '平台模式' : '商家模式',
                        ...item
                    })

                    for (const key in user) {
                        key != 'certificationPath' && !user[ key ] && user[ key ] != 0 && (user[ key ] = '暂无数据')
                    }

                    newList.push(user)
                }

                this.loading = false;
                this.usersList = newList;
            }).catch(err => {
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="less" scoped>
#business-users {
    width: 100%;
    // height: 100%;
    position: relative;
    overflow: auto;
    // display: flex;
    // flex-direction: column;

    .business-users-data {
        // flex: 1;
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        // display: flex;
        // flex-direction: column;
        overflow: auto;

        .users-list-wrapper {
            // flex: 1;
            width: 100%;
            margin: 20px auto;
            overflow: auto;
        }
    }
}
</style>