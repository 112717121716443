<template>
    <div class="change-pwd-wrapper">
        <a-modal :visible="show"
                 title="修改密码"
                 on-ok="handleSubmit"
                 @cancel="handleCancel">

            <template slot="footer">
                <a-button :loading="loading"
                          @click="handleSubmit">确定</a-button>
                <a-button key="back"
                          @click="handleCancel">关闭</a-button>
            </template>

            <a-form v-bind="formItemLayout"
                    :form="form"
                    @submit="handleSubmit">
                <a-form-item label="密码"
                             :help="passwordErr() || ''">
                    <a-input v-decorator="validate.password"
                             type="password"
                             :max-length="16"
                             placeholder="6-16位密码，区分大小写" />
                </a-form-item>
            </a-form>

        </a-modal>
    </div>
</template>

<script>
import { userChangPassword } from 'network/usersManage'

import md5 from "blueimp-md5";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("userManage");

export default {
    name: 'ChangePwd', // 修改密码弹窗
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            loading: false,

            form: this.$form.createForm(this, { name: "changePwd" }),

            formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 13 },
            },
            formSetAuth: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },

            validate: {
                password: [
                    "password",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请输入密码！",
                                pattern: /^[A-Za-z0-9_.%&',;=?$]{6,16}$/,
                            },
                        ],
                    },
                ],
            }
        }
    },
    computed: {
        ...mapGetters(["editUserId"]),
    },
    methods: {
        // ---------------事件处理函数---------------
        ...mapActions(["setEditUserIdAsync"]),

        // 密码错误提示
        passwordErr () {
            let tips = "";
            const { getFieldError, isFieldTouched, getFieldValue } = this.form;
            const flag =
                isFieldTouched("password") && getFieldError("password");

            if (flag) {
                const value = getFieldValue("password");

                if (value && value.length < 6) {
                    tips = "密码不能小于6位";
                } else {
                    tips = getFieldError("password");
                }
            }

            return tips;
        },
        handleCancel () {
            this.form.resetFields();

            this.setEditUserIdAsync("");

            this.$emit("update:show", false);
        },
        handleSubmit (e) {
            e.preventDefault();

            this.loading = true;

            this.form.validateFields((err, values) => {
                if (!err) {
                    let id = this.editUserId,
                        password = md5(values.password);
                    this._userChangPassword(id, password)
                } else {
                    this.loading = false;
                }
            });
        },

        // -----------------网络请求-----------------
        // 修改密码
        _userChangPassword (id, password) {
            this.loading = true

            userChangPassword(id, password).then(res => {
                this.loading = false;
                this.$message.success(res.message);

                this.handleCancel();
            }).catch(err => {
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="less" scoped>
</style>