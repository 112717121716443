<template>
    <div class="users-list"
         ref="listBox">
        <a-table :columns="columns"
                 :data-source="list"
                 :pagination="false"
                 :loading="loading"
                 :scroll="scroll"
                 :row-key="record => record.id"
                 :row-selection="{ selectedRowKeys: checkKeys, onChange: onSelectChange }">
            <!-- 认证资料 -->
            <div slot='authData'
                 slot-scope="record">
                <a class="check-auth"
                   v-if="record.certificationPath"
                   :href="($fileUrl + '/' + record.certificationPath)"
                   target="_blank">{{ record.authData }}</a>
                <a v-else
                   href="javascript:;">{{ record.authData }}</a>
            </div>

            <div class="btns"
                 slot="action"
                 slot-scope="record">
                <a-button class="btn"
                          type="primary"
                          @click="handleEdit(record)">编辑</a-button>

                <a-button class="btn"
                          type="primary"
                          @click="changePassword(record)">修改密码</a-button>

                <a-popconfirm title="是否启用？"
                              @confirm="onEnable(record)">
                    <a-button class="btn"
                              type="primary">启用</a-button>
                </a-popconfirm>

                <a-popconfirm title="是否停用？"
                              @confirm="onStop(record)">
                    <a-button class="btn"
                              type="danger">停用</a-button>
                </a-popconfirm>
            </div>
        </a-table>
    </div>
</template>

<script>
import { userOpenClose } from 'network/usersManage'

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('userManage');

export default {
    name: "BusinessUsersList", // 用户列表
    props: {
        list: {
            type: Array,
            default () {
                return [];
            },
        },
        checkKeys: {
            type: Array,
            default () {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            columns: [
                {
                    title: "商家账号",
                    dataIndex: "username",
                    key: "username",
                    width: 130,
                    fixed: "left",
                },
                {
                    title: "商家名称",
                    dataIndex: "nickname",
                    key: "nickname",
                    width: 130,
                    fixed: "left",
                },
                {
                    title: "认证资料",
                    // dataIndex: "authData",
                    key: "authData",
                    width: 130,
                    scopedSlots: { customRender: 'authData' },
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    key: "status",
                    width: 130,
                },
                {
                    title: "账户余额(元)",
                    dataIndex: "balance",
                    key: "balance",
                    width: 130,
                },
                {
                    title: "总实收(元)",
                    dataIndex: "total",
                    key: "total",
                    width: 130,
                },
                {
                    title: "结算模式",
                    dataIndex: "mode",
                    key: "mode",
                    width: 130,
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: 150,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 260,
                    align: "center",
                    scopedSlots: { customRender: "action" },
                },
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        // ---------------事件处理函数----------------
        ...mapActions(['setEditUserIdAsync']),

        onSelectChange (selectedRowKeys) {
            this.$emit('update:checkKeys', selectedRowKeys)
        },

        // 编辑
        handleEdit (value) {
            this.setEditUserIdAsync(value.id)
            this.$emit('onEdit')
        },

        // 修改密码
        changePassword (value) {
            this.setEditUserIdAsync(value.id)
            this.$emit('onChangePwd')
        },

        // 启用
        onEnable (value) {
            this._userOpenClose(0, value.id)
        },

        // 停用
        onStop (value) {
            this._userOpenClose(1, value.id)
        },

        // ----------------网络请求----------------
        // 启用/停用
        _userOpenClose (closed, ids) {
            userOpenClose(closed, ids).then(res => {
                this.$message.success(res.message);

                this.$emit('onSuccess')
            }).catch(err => { })
        },
    },
};
</script>

<style lang="less" scoped>
.users-list {
    width: 100%;
    // height: 100%;
    overflow: auto;

    .check-auth {
        color: var(--color-tint);
        text-decoration: underline;
    }

    .btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto;

        .btn {
            min-width: 80px;
            margin: 10px auto;
        }
    }
}
</style>