<template>
    <div class="business-btns">
        <a-button class="btn" type="primary" @click="handleShowModal">新建账号</a-button>
        <a-button class="btn" type="primary" @click="handleOpen">启用账号</a-button>
        <a-button class="btn" type="danger" @click="handleClose">停用账号</a-button>
    </div>
</template>

<script>
import { userOpenClose } from 'network/usersManage'

export default {
    name: "BusinessBtns", // 商家操作按钮
    props: {
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        // -------------事件处理函数---------------
        handleShowModal() {
            this.$emit('onShowModal')
        },
        // 启用账号
        handleOpen() {
            let ids = this.checkKeys.join(',');
            this._userOpenClose(0, ids)
        },
        // 停用账号
        handleClose() {
            let ids = this.checkKeys.join(',');
            this._userOpenClose(1, ids)
        },

        // -------------网络请求---------------
        // 启用/停用
        _userOpenClose(closed, ids) {
            userOpenClose(closed, ids).then(res => {
                this.$message.success(res.message);
                
                this.$emit('onSuccess')
            }).catch(err => {})
        },
    },
};
</script>

<style lang="less" scoped>
.business-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>