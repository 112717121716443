<template>
    <div class="business-search-wrapper">
        <a-form class="search-wrapper"
                layout="inline"
                :form="form"
                @submit="handleSearch">

            <!-- 商家名称 -->
            <a-form-item label="商家名称"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['nickname', { initialValue: '' }]"
                         placeholder="按商家名称搜索" />

            </a-form-item>

            <!-- 商家账号 -->
            <a-form-item label="商家账号"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['account', { initialValue: '' }]"
                         placeholder="按账号搜索" />

            </a-form-item>

            <!-- 状态 -->
            <a-form-item label="状态"
                         class="form-item-wrapper status-form"
                         :label-col="formStatus.labelCol"
                         :wrapper-col="formStatus.wrapperCol">

                <a-select v-decorator="['status', { initialValue: '0' }]">

                    <template v-for="item in status">
                        <a-select-option :value="item.value"
                                         :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>

                </a-select>

            </a-form-item>

            <!-- 查询/重置 -->
            <a-form-item class="form-item-wrapper">
                <a-button class="submit-btn"
                          type="primary"
                          html-type="submit">
                    查询
                </a-button>

                <a-button @click="resetForm">
                    重置
                </a-button>
            </a-form-item>

        </a-form>
    </div>
</template>

<script>
export default {
    name: 'BusinessSearch', // 商家用户筛选
    data () {
        return {
            formName: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            formStatus: {
                labelCol: { span: 5 },
                wrapperCol: { span: 14 },
            },
            form: this.$form.createForm(this, { name: 'ordinarySearch' }),

            status: [
                {
                    name: '全部',
                    value: '0'
                },
                {
                    name: '启用',
                    value: '1'
                },
                {
                    name: '停用',
                    value: '2'
                }
            ]
        }
    },
    methods: {
        // 点击查询
        handleSearch (e) {
            e.preventDefault();
            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        },
        // 重置
        resetForm() {
            this.form.resetFields()

            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        }
    }
}
</script>

<style lang="less" scoped>
.business-search-wrapper {
    width: 100%;

    .search-wrapper {
        width: 100%;

        .form-item-wrapper {
            width: 300px;

            .submit-btn {
                margin-right: 20px;
            }
        }
    }
}
</style>