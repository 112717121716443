<template>
    <div class="business-create-edit">
        <a-modal
            :visible="show"
            title="新建/编辑账号"
            on-ok="handleSubmit"
            @cancel="handleCancel"
        >
            <template slot="footer">
                <a-button :loading="loading" @click="handleSubmit"
                    >确定</a-button
                >
                <a-button key="back" @click="handleCancel">关闭</a-button>
            </template>

            <a-form v-bind="formItemLayout" :form="form" @submit="handleSubmit">
                <a-form-item label="账号">
                    <a-input
                        v-decorator="validate.username"
                        placeholder="请输入账号"
                    />
                </a-form-item>

                <a-form-item label="名称">
                    <a-input
                        v-decorator="validate.nickname"
                        placeholder="请输入名称"
                    />
                </a-form-item>

                <a-form-item label="密码" :help="passwordErr() || ''" v-if="!editUserId">
                    <a-input
                        v-decorator="validate.password"
                        type="password"
                        :max-length="16"
                        placeholder="6-16位密码，区分大小写"
                    />
                </a-form-item>

                <a-form-item label="真实姓名">
                    <a-input
                        v-decorator="validate.name"
                        placeholder="请输入真实姓名"
                    />
                </a-form-item>

                <a-form-item label="认证资料">
                    <a-upload
                        v-decorator="validate.upload"
                        name="file"
                        :action="$baseUrl + upload"
                        :headers="header"
                        method="post"
                        list-type="picture"
                    >
                        <a-button> <a-icon type="upload" /> 上传图片 </a-button>
                    </a-upload>
                </a-form-item>

                <a-form-item label="备注">
                    <a-textarea
                        v-decorator="validate.remarks"
                        placeholder="请输入备注"
                    />
                </a-form-item>

                <a-form-item
                    label="结算模式"
                    help="结算模式产生订单后不能修改！请谨慎选择！"
                >
                    <a-select v-decorator="validate.payMode">
                        <a-select-option value="0">
                            平台模式（钱款到平台账户，商家提现）
                        </a-select-option>
                        <a-select-option value="1">
                            商家模式（欠款到商家用户，不需提现）
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    label="生产设置权限"
                    :label-col="formSetAuth.labelCol"
                    :wrapper-col="formSetAuth.wrapperCol"
                >
                    <a-radio-group v-decorator="validate.setAuth">
                        <a-radio value="0"> 开放生产设置 </a-radio>
                        <a-radio value="1"> 不开放生产设置 </a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import api from "network/api";

import { createUser, editUser, getUserDetail } from "network/usersManage";

import { getHeader } from "common/utils";

import md5 from "blueimp-md5";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("userManage");

export default {
    name: "BusinessCreateEdit", // 商家账号创建/编辑
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,

            form: this.$form.createForm(this, { name: "register" }),

            formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 13 },
            },
            formSetAuth: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },

            fileList: [],
            upload: api.upload.upload,
            header: getHeader("multipart/form-data"),

            editModelPassword: '', // 编辑时的密码

            validate: {
                username: [
                    "username",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请输入账号！",
                                pattern: /^[A-Za-z0-9]+$/,
                            },
                        ],
                    },
                ],
                nickname: [
                    "nickname",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请输入名称！",
                            },
                        ],
                    },
                ],
                password: [
                    "password",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请输入密码！",
                                pattern: /^[A-Za-z0-9_.%&',;=?$]{6,16}$/,
                            },
                        ],
                    },
                ],
                name: ["name"],
                upload: [
                    "upload",
                    {
                        valuePropName: "fileList",
                        getValueFromEvent: this.normFile,
                    },
                ],
                remarks: ["remarks"],
                payMode: [
                    "payMode",
                    {
                        initialValue: "0",
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    },
                ],
                setAuth: [
                    "setAuth",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请选择生产设置权限！",
                            },
                        ],
                    },
                ],
            },
        };
    },

    computed: {
        ...mapGetters(["editUserId"]),
    },

    watch: {
        editUserId(newVal) {
            if (newVal && this.show) {
                this._getUserDetail(newVal);
            }
        },
    },

    methods: {
        // ----------------事件处理函数---------------
        ...mapActions(["setEditUserIdAsync"]),

        // 密码错误提示
        passwordErr() {
            let tips = "";
            const { getFieldError, isFieldTouched, getFieldValue } = this.form;
            const flag =
                isFieldTouched("password") && getFieldError("password");

            if (flag) {
                const value = getFieldValue("password");

                if (value && value.length < 6) {
                    tips = "密码不能小于6位";
                } else {
                    tips = getFieldError("password");
                }
            }

            return tips;
        },

        // 图片上传返回的列表
        normFile(e) {
            let list = [];

            if (Array.isArray(e)) {
                list.push(e[e.length - 1]);
            } else if (e && e.fileList) {
                list.push(e.fileList[e.fileList.length - 1]);
            }

            for (let i = 0; i < list.length; i++) {
                if (list[i].response && list[i].response.status != 1) {
                    list[i].status = "error";
                } else if (list[i].response && list[i].response.status == 1) {
                    list[i].thumbUrl =
                        this.$fileUrl + "/" + list[i].response.data.filePath;
                    list[i].picUrl = list[i].response.data.filePath;
                }
            }

            this.fileList = list;

            return list;
        },

        handleCancel() {
            this.form.resetFields();

            this.setEditUserIdAsync("");

            this.$emit("update:show", false);
        },
        handleSubmit(e) {
            e.preventDefault();

            this.loading = true;

            this.form.validateFields((err, values) => {
                if (!err) {
                    let picUrl = "";
                    if (values.upload) {
                        picUrl = values.upload[0].picUrl;
                    }

                    let params = {
                        phone: values.phone || "",
                        realname: values.name,
                        remark: values.remarks || "",
                        userType: 1,
                        username: values.username,
                        certificationPath: picUrl,
                        operatorName: values.nickname || "",
                        productionPermissions: +values.setAuth,
                        settlementMode: +values.payMode,
                    };

                    if (this.editUserId) {
                        params.id = this.editUserId;
                        params.password = this.editModelPassword;
                        this._editUser(params);

                        return;
                    } else {
                        params.password = md5(values.password);
                    }

                    this._createUser(params);
                } else {
                    this.loading = false;
                }
            });
        },

        // -----------------网络请求-----------------
        // 创建账号
        _createUser(params) {
            createUser(params)
                .then((res) => {
                    this.$message.success(res.message);

                    this.$emit("onSuccess");
                    this.handleCancel();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        // 编辑用户
        _editUser(params) {
            editUser(params)
                .then((res) => {
                    this.$message.success(res.message);

                    this.$emit("onSuccess");
                    this.handleCancel();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        // 获取用户信息
        _getUserDetail(id) {
            getUserDetail(id)
                .then((res) => {
                    const data = res.data;

                    this.editModelPassword = data.password;

                    this.form.setFieldsValue({ username: data.username });
                    this.form.setFieldsValue({ nickname: data.operatorName });
                    this.form.setFieldsValue({ name: data.realname });
                    this.form.setFieldsValue({ remarks: data.remark });

                    this.validate.payMode[1].initialValue = String(
                        data.settlementMode
                    );
                    this.form.getFieldDecorator(
                        "payMode",
                        this.validate.payMode[1]
                    );

                    this.validate.setAuth[1].initialValue = String(
                        data.productionPermissions
                    );
                    this.form.getFieldDecorator(
                        "setAuth",
                        this.validate.setAuth[1]
                    );

                    if (data.certificationPath) {
                        this.fileList = [
                            {
                                uid: "-1",
                                name: "认证资料",
                                status: "done",
                                picUrl: data.certificationPath,
                                thumbUrl:
                                    this.$fileUrl +
                                    "/" +
                                    data.certificationPath,
                            },
                        ];
                        this.form.setFieldsValue({ upload: this.fileList });
                    }
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="less" scoped>
</style>